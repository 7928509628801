import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

// 把组件按组分块 /* webpackChunkName: "about" */
const routes = [
  {
    path: '/',
    name: 'base',
    component: () => import('@/views/satin-base-layout/BaseLayout'),
    redirect: '/index',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/satin-login-layout/WechatLogin')
      },
      {
        path: 'index',
        name: 'index',
        component: () => import(/* webpackChunkName: "satin" */ '@/views/satin-content-layout/ContentLayout'),
        redirect: '/index/satin-offer',
        children: [
          {
            path: 'general-category',
            name: 'GeneralCategory',
            component: () => import(/* webpackChunkName: "satin" */ '@/views/satin-general-category/GeneralCategory')
          },
          {
            path: 'satin-offer',
            name: 'SatinOffer',
            component: () => import(/* webpackChunkName: "satin" */ '@/views/satin-offer/SatinOffer')
          },
          {
            path: 'flower-type',
            name: 'FlowerType',
            component: () => import(/* webpackChunkName: "flower" */ '@/views/flower-type/FlowerType')
          },
          {
            path: 'flower-picture',
            name: 'FlowerPicture',
            component: () => import(/* webpackChunkName: "flower" */ '@/views/flower-picture/FlowerPicture')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
  } else {
    if (store.getters.userInfo.token) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
