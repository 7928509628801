import { createStore } from 'vuex'
const userInfo = { token: '' }
if (localStorage.getItem('userInfo')) {
  Object.assign(userInfo, JSON.parse(localStorage.getItem('userInfo')))
}

export default createStore({
  state: {
    // 登录信息
    userInfo: userInfo,
    // 是否去登录
    willLogin: false
  },
  getters: {
    userInfo: state => state.userInfo,
    willLogin: state => state.willLogin
  },
  mutations: {
    setUserInfo: (state, payload) => {
      state.userInfo = payload
    },
    setWillLogin: (state, payload) => {
      state.willLogin = payload
    }
  },
  actions: {
    userInfo: (inject, payload) => {
      inject.commit('setUserInfo', payload)
      localStorage.setItem('userInfo', JSON.stringify(payload))
    },
    willLogin: (inject, payload) => {
      inject.commit('setWillLogin', payload)
    }
  },
  modules: {}
})
